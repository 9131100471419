.cta {
    *{
      color: $white !important;
    }
    .card__image{
      background-position: right bottom !important;
      background-size: contain !important;
    }
  
    &--img-bttm {
      padding-bottom: 200px;
      .card__image{
        position: absolute;
        height: 200px;
        right: 0 !important;
        width: 100% !important;
        bottom: 0;
      }
  
      .pr-4{
        padding-right: 0 !important;
      }
    }

    &--large {
        background-color: $secondary;
        padding         : 60px;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}