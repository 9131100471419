.product-navbar {
    background-color: $primary;
    padding         : 15px;
  
    .uk-navbar-nav {
      >li {
        >a {
          margin    : 0;
          padding   : 0px 15px;
          min-height: 0;
  
          font-size  : 18px;
          line-height: 27px;
          color      : $white;
  
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  
    .uk-navbar-dropdown {
      background-color: $white;
      border          : 1px solid $border;
      border-radius   : 0 0 $border-radius $border-radius;
      padding         : 22px 30px 30px;
  
      .uk-nav-divider {
        margin: 25px 0;
      }
  
      .uk-nav-sub-header {
        font-weight   : bold;
        text-transform: uppercase;
        font-size     : 12px;
        letter-spacing: 0.1em;
        line-height   : 12px;
        color         : $text;
      }
  
      .uk-nav {
        li {
          a {
            font-weight: 300;
            font-size  : 16px;
            line-height: 28px;
            color      : $black;
            padding    : 0;
  
            &:hover {
              color          : $primary;
              text-decoration: underline;
            }
          }
        }
      }
  
      .uk-nav-header {
        a {
          font-weight   : bold !important;
          font-size     : 18px !important;
          line-height   : 27px !important;
          color         : $black !important;
          text-transform: none !important;
  
          &:hover {
            color          : $primary !important;
            text-decoration: none !important;
          }
        }
  
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}