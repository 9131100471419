.mobile-nav {
    background-color: $secondary;
    padding         : 20px 0;
  
    .uk-navbar-toggle {
      color       : $black;
      margin-right: 20px;
      padding     : 0;
      min-height  : 0;
    }
  
    .uk-logo {
      padding: 0;
    }
  
    .uk-navbar-item {
      min-height: 0;
    }
  
    .uk-navbar-nav {
      li {
        a {
          padding   : 0 10px;
          min-height: 0;
          color     : $black;
        }
      }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
    #mobile-nav {
        .uk-offcanvas-bar {
          width: 90vw;
          left: -90vw;
        }
    
        &.uk-open {
          .uk-offcanvas-bar {
            left: 0;
          }
        }
    }
}