.brand-grid {
    display              : grid;
    grid-template-columns: repeat(7, 1fr);
    gap                  : 10px 10px;
  
    >a {
      border         : 1px solid $border;
      height         : 128px;
      display        : flex;
      justify-content: center;
      align-items    : center;
      padding        : 25px;
      transition     : border 0.25s ease, background-color 0.25s ease;
  
      &:hover {
        border          : 1px solid $primary;
        background-color: $secondary;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
    .brand-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}

// Mobile devices
@media (max-width: 640px) {
    .brand-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}