.uk-table {
    &-striped {
      &--alt {
        tbody tr:nth-of-type(odd) {
          background-color: $secondary;
        }
      }
    }
  
    &-wysiwyg {
      thead {
        th {
          background-color: $black;
          color           : $white;
        }
      }
  
      &--alt {
        thead th {
          background-color: $primary;
        }
      }
    }
  
    &-hover {
  
      >tr:hover,
      tbody tr:hover {
        background-color: $primary;
  
        * {
          color      : $white;
          font-weight: bold;
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}