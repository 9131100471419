.sidebar {
    >li {
      border-bottom : 1px solid $border;
      padding-bottom: 30px;
  
      &:last-of-type {
        border-bottom : 0;
        padding-bottom: 0;
      }
  
      a.uk-accordion-title {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
  
        span {
          font-weight: bold;
          font-size  : 18px;
          line-height: 27px;
          color      : $black;
        }
  
        i,
        svg {
          font-size: 12px;
          color    : $black;
        }
      }
  
      &.uk-open {
        a.uk-accordion-title {
  
          i,
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  
    &__nav {
      list-style-type: none;
      margin         : 0;
      padding        : 0;
  
      li {
        a {
          display        : flex;
          justify-content: space-between;
          align-items    : center;
  
          .text {
            font-weight: 300;
            font-size  : 16px;
            line-height: 28.8px;
            color      : $black;
          }
  
          .amount {
            font-weight: 300;
            font-size  : 14px;
            line-height: 30px;
            color      : $grey-dark;
          }
  
          &:hover {
            text-decoration: none;
  
            .text {
              color      : $primary;
              font-weight: bold;
            }
          }
        }
      }
    }
  
    &__price {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
  
      >p {
        flex: 0 1 auto;
      }
  
      >input[type='number'] {
        width : 70px;
        flex  : 0 0 70px;
        height: 50px;
  
        border       : 1px solid $border;
        border-radius: 3px;
        padding-left : 15px;
        padding-right: 15px;
  
        font-family: $ff-stack;
        font-weight: 300;
        font-size  : 16px;
        line-height: 30px;
        color      : $black;
  
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin            : 0;
        }
  
        -moz-appearance: textfield;
  
        &:focus {
          outline: none;
          border : 1px solid $primary;
        }
      }
  
      >button[type='submit'] {
        width : 50px;
        flex  : 0 0 50px;
        height: 50px;
        cursor: pointer;
  
        background-color: $primary;
        color           : $white;
        border          : 0;
        border-radius   : 3px;
        font-size       : 18px;
  
        &:hover {
          background-color: darken($color: $primary, $amount: 8%);
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}