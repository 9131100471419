/*========================================================
=                       Utilities                        =
=========================================================*/


/* positioning & alignment
-------------------------------------------------- */

.push-down, .flex-grow {
    flex-grow: 1;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.pull-right--mini {
    margin-right: -1px;
}

.pull-bottom--mini {
    margin-bottom: -1px;
}

.ex-p-rel {
    position: relative;
}

.ex-p-abs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ex-p-center {
    margin: auto;
    display: block;
}

.ex-wr-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* blend modes
-------------------------------------------------- */

.blend {
    &--multiply {
        mix-blend-mode: multiply;
    }
}


/* Spacing
-------------------------------------------------- */

.margin-top-verthus {
    margin-top: 30px;
}

@for $i from 0 through 40 {
    $j: $i * 5;

    .ex-mt--#{$j} {
        margin-top: 1px * $j;
    }

    .ex-mr--#{$j} {
        margin-right: 1px * $j;
    }

    .ex-mb--#{$j} {
        margin-bottom: 1px * $j;
    }

    .ex-ml--#{$j} {
        margin-left: 1px * $j;
    }
}

@for $i from 0 through 40 {
    $j: $i * 5;

    .ex-pt--#{$j} {
        padding-top: 1px * $j;
    }

    .ex-pr--#{$j} {
        padding-right: 1px * $j;
    }

    .ex-pb--#{$j} {
        padding-bottom: 1px * $j;
    }

    .ex-pl--#{$j} {
        padding-left: 1px * $j;
    }
}


/* Sizing
-------------------------------------------------- */

.ex-w-15 {
    width: 15%;
}

.ex-w-25 {
    width: 25%;
}

.ex-w-50 {
    width: 50%;
}

.ex-w-75 {
    width: 75%;
}

.ex-w-100 {
    width: 100%;
}

.ex-h-100 {
    height: 100%;
}


/* Border
-------------------------------------------------- */

.ex-br-circle {
    border-radius: 99999px;
}

.ex-br-top {
    border-radius: 25px 25px 0 0;
}

.ex-br-25 {
    border-radius: 25px;
}

hr {
    border-top: 1px solid $border;
}

/* Backgrounds
-------------------------------------------------- */

.bg {

    &--primary {
        background: $primary;
    }

    &--secondary {
        background: $secondary;
    }
}


/* Hover
-------------------------------------------------- */
.no-hover {
    &:hover {
      text-decoration: none;
    }
}