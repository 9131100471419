.top-bar {
    background-color: $secondary;
    padding         : 15px 0;
  
    &__nav {
      display        : flex;
      justify-content: flex-end;
      align-items    : center;
      list-style-type: none;
      margin         : 0;
      padding        : 0;
  
      >li {
        margin: 0px 10px;
  
        &:first-of-type {
          margin-left: 0;
        }
  
        &:last-of-type {
          margin-right: 0;
        }
  
        >a {
          color      : $black;
          font-size  : 14px;
          line-height: 21px;
  
          &:hover {
            text-decoration: none;
            color          : $primary;
          }
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}