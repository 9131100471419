.product {
    display        : flex;
    flex-flow      : column;
    justify-content: space-between;
    align-items    : flex-start;
    height         : 100%;
    
    &__header {
      width: 100%;
      position: relative;
    }

    &__image {
    }
  
    &__like {
      position: absolute;
      top: 20px;
      right: 20px;
      color           : $primary;
      cursor          : pointer;
      background-color: $white;
      border-radius   : 3px;
      border          : 0;
      padding         : 6px;
  
      >.normal {
        display: block;
      }
  
      >.active {
        display: none;
      }
  
      &:hover,
      &--liked {
        >.normal {
          display: none;
        }
  
        >.active {
          display: block;
        }
      }
    }

    &__info {
      margin-top: 30px;
      display        : flex;
      flex-flow      : column;
      justify-content: flex-start;
      align-items    : flex-start;
      margin-bottom  : 20px;
    }

    &__title {
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
      color: $black;
    }
  
    &__footer {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
      width          : 100%;
    }
  
    &__price {
      display        : flex;
      justify-content: flex-start;
      align-items    : baseline;

      &--label {
        font-weight    : 300;
        font-size      : 16px;
        line-height    : 27px;
        color          : $black;
        margin: 0 10px 0 0;
      }
  
      &--current {
        font-weight : bold;
        font-size   : 24px;
        line-height : 28.8px;
        color       : $black;
        margin: 0 10px 0 0;
      }
  
      &--old {
        font-weight    : 300;
        font-size      : 18px;
        line-height    : 27px;
        color          : $black;
        text-decoration: line-through;
        margin: 0;
      }
    }
  
    &__cart {
      flex            : 0 0 50px;
      width           : 50px;
      height          : 50px;
      background-color: $success-color;
      border          : 0;
      display         : flex;
      justify-content : center;
      align-items     : center;
      color           : $white;
      margin-left     : 20px;
      border-radius   : 3px;
      cursor          : pointer;
  
      &:hover {
        background-color: darken($color: $success-color, $amount: 4%);
      }
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
  
      .product__title {
        color: $primary;
      }
    }
  }
  
  .product__stock {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
  
    .icon {
      color       : $white;
      font-size   : 14px;
      margin-right: 10px;
    }
  
    p {
      margin     : 0;
      font-weight: bold;
      font-size  : 14px;
      line-height: 21px;
    }
  
    &--success {
      .icon {
        color: $success-color;
      }
  
      p {
        color: $success-color;
      }
    }
  
    &--warning {
      .icon {
        color: $warning-color;
      }
  
      p {
        color: $warning-color;
      }
    }
  
    &--error {
      .icon {
        color: $danger-color;
      }
  
      p {
        color: $danger-color;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}