.top-banner {
    background-color: $primary;
  
    &--title {
      color: $white;
    }
    &--text {
      color: $white;
  
      p {
        color: $white;
      }
      
      p:last-of-type {
        margin-bottom: 0;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}