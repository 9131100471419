.main-navbar {
    display              : grid;
    grid-template-columns: 1fr 2fr 1.5fr;
    gap                  : 20px 20px;
    padding              : 25px 0;
    align-content        : center;
  
    &__left {
      .uk-logo {
        display: block;
      }
  
      img {
        max-width: 100%;
      }
    }
  
    &__center {
      display    : flex;
      align-items: center;
    }
  
    &__right {
      display        : flex;
      justify-content: flex-end;
      align-items    : center;
  
      >a,
      >.dropdown-item>a {
        display        : flex;
        flex-flow      : column;
        justify-content: center;
        align-items    : center;
        color          : $black;
        margin-right   : 30px;
  
        span {
          margin-top : 10px;
          font-weight: normal;
          font-size  : 16px;
        }
  
        &:hover {
          color          : $primary;
          text-decoration: none;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
        &.cart-button {
          position: relative;
  
          .amount {
            position        : absolute;
            top             : -7px;
            left            : calc(50% + 3px);
            width           : 18px;
            height          : 18px;
            background-color: $primary;
            display         : flex;
            justify-content : center;
            align-items     : center;
            color           : $white;
            margin          : 0;
            z-index         : 9;
            border-radius   : 9999px;
  
            font-weight: bold;
            font-size  : 12px;
            text-align : center;
          }
        }
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
}