@charset "UTF-8";
/* Fonts
-------------------------------------------------- */
/* Colors
-------------------------------------------------- */
/* Border radius
-------------------------------------------------- */
/* Shadows
-------------------------------------------------- */
/* Mixin's
-------------------------------------------------- */
/* Functions
-------------------------------------------------- */
/* iframe videos
-------------------------------------------------- */
.vimeo-iframe, .youtube-iframe, .other-iframe {
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100% !important;
}

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.image-block.square {
  padding-top: 100%;
}
.image-block.long {
  padding-top: 133%;
}
.image-block--half {
  padding-top: 56%;
}
.image-block.contain {
  background-size: contain;
}

/* Modals
-------------------------------------------------- */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(231, 248, 248, 0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow-y: scroll;
}
.modal--big {
  align-items: flex-start;
}
.modal__box {
  width: 100%;
  max-width: 1050px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  padding: 45px 60px;
}
.modal__box--image {
  position: relative;
}
.modal__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 66.66%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.modal__content {
  padding-left: calc(33.33% + 50px);
}

/* Category blocks
-------------------------------------------------- */
.block-link-category-wrapper {
  text-align: center;
}
.block-link-category-wrapper span {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
.block-link-category-wrapper:hover {
  text-decoration: none;
}
.block-link-category-wrapper:hover span {
  color: #18bbb9;
  text-decoration: none !important;
}
.block-link-category-wrapper:hover .block-link-category {
  background-color: #18bbb9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-link-category-wrapper:hover .block-image-link-category {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.block-link-category {
  position: relative;
  background-color: #e7f8f8;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.block-link-category span {
  position: absolute;
  left: 25px;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #222222;
  transition: color 0.3s ease;
}
.block-link-category:hover {
  background-color: #18bbb9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-link-category:hover span {
  color: #ffffff;
}

.block-image-link-category {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}
.block-image-link-category .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s ease;
}
.block-image-link-category span {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  transition: color 0.3s ease;
}
.block-image-link-category:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-image-link-category:hover .overlay {
  background-color: rgba(24, 187, 185, 0.7);
}

/* General
-------------------------------------------------- */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url("/dist/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

.lazyload, .lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;
}
.lazyload-wrapper img {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/* Product detail page
-------------------------------------------------- */
.cart-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*========================================================
=                       Typography                        =
=========================================================*/
h1,
.uk-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #222222;
}

h2,
.uk-h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38.4px;
  color: #222222;
}

h3,
.uk-h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: #222222;
}

h4,
.uk-h4 {
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}

p,
.cms,
.text {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
p em,
.cms em,
.text em {
  color: #222222;
}
p a,
.cms a,
.text a {
  color: #18bbb9;
  text-decoration: underline;
}
p a:hover, p a:active, p a:focus,
.cms a:hover,
.cms a:active,
.cms a:focus,
.text a:hover,
.text a:active,
.text a:focus {
  color: #139795;
}
p table,
.cms table,
.text table {
  border: 1px solid #222222;
  border-collapse: collapse;
}
p table th, p table td,
.cms table th,
.cms table td,
.text table th,
.text table td {
  padding: 8px 15px;
}
p--big,
.cms--big,
.text--big {
  font-size: 18px;
  line-height: 27px;
}
p--big p,
.cms--big p,
.text--big p {
  font-size: 18px;
  line-height: 27px;
}
p--small,
.cms--small,
.text--small {
  font-size: 14px;
  line-height: 21px;
}
p--small p,
.cms--small p,
.text--small p {
  font-size: 14px;
  line-height: 21px;
}
p--tiny,
.cms--tiny,
.text--tiny {
  font-size: 11px;
}
p--tiny p,
.cms--tiny p,
.text--tiny p {
  font-size: 11px;
}
p--white,
.cms--white,
.text--white {
  color: #ffffff;
}
p--white p,
.cms--white p,
.text--white p {
  color: #ffffff;
}
p--grey,
.cms--grey,
.text--grey {
  color: #e8e8e8;
}
p--grey p,
.cms--grey p,
.text--grey p {
  color: #e8e8e8;
}
p--grey-dark,
.cms--grey-dark,
.text--grey-dark {
  color: #666666;
}
p--grey-dark p,
.cms--grey-dark p,
.text--grey-dark p {
  color: #666666;
}
p--black,
.cms--black,
.text--black {
  color: #222222;
}
p--black p,
.cms--black p,
.text--black p {
  color: #222222;
}
p--primary,
.cms--primary,
.text--primary {
  color: #18bbb9;
}
p--primary p,
.cms--primary p,
.text--primary p {
  color: #18bbb9;
}
p--success,
.cms--success,
.text--success {
  color: #1eaf3a;
}
p--success p,
.cms--success p,
.text--success p {
  color: #1eaf3a;
}
p--warning,
.cms--warning,
.text--warning {
  color: #ff6601;
}
p--warning p,
.cms--warning p,
.text--warning p {
  color: #ff6601;
}
p--danger,
.cms--danger,
.text--danger {
  color: #ff2424;
}
p--danger p,
.cms--danger p,
.text--danger p {
  color: #ff2424;
}
p--center,
.cms--center,
.text--center {
  text-align: center;
}
p--center h1, p--center .uk-h1,
p--center h2, p--center .uk-h2,
p--center h3, p--center .uk-h3,
p--center h4, p--center .uk-h4,
p--center h5, p--center .uk-h5,
p--center p, p--center .cms, p--center .text,
.cms--center h1,
.cms--center .uk-h1,
.cms--center h2,
.cms--center .uk-h2,
.cms--center h3,
.cms--center .uk-h3,
.cms--center h4,
.cms--center .uk-h4,
.cms--center h5,
.cms--center .uk-h5,
.cms--center p,
.cms--center .cms,
.cms--center .text,
.text--center h1,
.text--center .uk-h1,
.text--center h2,
.text--center .uk-h2,
.text--center h3,
.text--center .uk-h3,
.text--center h4,
.text--center .uk-h4,
.text--center h5,
.text--center .uk-h5,
.text--center p,
.text--center .cms,
.text--center .text {
  text-align: center;
}
p--right,
.cms--right,
.text--right {
  text-align: right;
}
p--right h1, p--right .uk-h1,
p--right h2, p--right .uk-h2,
p--right h3, p--right .uk-h3,
p--right h4, p--right .uk-h4,
p--right h5, p--right .uk-h5,
p--right p, p--right .cms, p--right .text,
.cms--right h1,
.cms--right .uk-h1,
.cms--right h2,
.cms--right .uk-h2,
.cms--right h3,
.cms--right .uk-h3,
.cms--right h4,
.cms--right .uk-h4,
.cms--right h5,
.cms--right .uk-h5,
.cms--right p,
.cms--right .cms,
.cms--right .text,
.text--right h1,
.text--right .uk-h1,
.text--right h2,
.text--right .uk-h2,
.text--right h3,
.text--right .uk-h3,
.text--right h4,
.text--right .uk-h4,
.text--right h5,
.text--right .uk-h5,
.text--right p,
.text--right .cms,
.text--right .text {
  text-align: right;
}

p {
  margin-bottom: 0;
}
p + p {
  margin-top: 24px;
}

.no-hover:hover {
  text-decoration: none;
}

.input-errors p {
  color: #ff2424;
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;
  font-weight: 700;
}

sub, sup {
  font-size: 100%;
}

address {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
address a {
  color: #18bbb9;
  text-decoration: underline;
}
address a:hover, address a:active, address a:focus {
  color: #139795;
}

ol, ul {
  padding-left: 20px;
}
ol li, ul li {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
ol li::marker, ul li::marker {
  color: #18bbb9;
  font-size: 20px;
}

.cs-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.cs-wrapper .cs-inner {
  max-width: 500px;
  padding-right: 32px;
  padding-left: 32px;
}
.cs-wrapper .cs-inner .cs-card {
  background-color: #ffffff;
}
.cs-wrapper .cs-title {
  color: #222222;
  font-size: 24px;
}
.cs-wrapper .cs-text {
  color: rgba(34, 34, 34, 0.75);
  font-family: sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.cs-wrapper .cs-socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cs-wrapper .cs-socials a {
  margin: 0px 4px;
  color: rgba(34, 34, 34, 0.65);
  font-size: 18px;
}
.cs-wrapper .cs-socials a:hover {
  color: #222222;
  text-decoration: none;
}

.wrapper-404 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
}
.wrapper-404 .overlay-404 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  align-items: center;
  justify-content: center;
}
.wrapper-404 .overlay-404 .inner-404 {
  max-width: 500px;
  padding: 50px;
  border-radius: 4px;
  background-color: #ffffff;
}
.wrapper-404 .overlay-404 .inner-404 h1 {
  margin-bottom: 15px;
  background: linear-gradient(135deg, rgba(116, 235, 213, 0.67) 0%, rgba(172, 182, 229, 0.67) 100%);
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 800;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wrapper-404 .overlay-404 .inner-404 h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
}
.wrapper-404 .overlay-404 .inner-404 p {
  margin-bottom: 0;
}
.wrapper-404 .overlay-404 .inner-404 p a {
  color: rgb(116, 235, 213);
}
.wrapper-404 .overlay-404 .inner-404 p a:hover {
  color: #31e1c1;
}

.edit__page__button {
  z-index: 9999;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  color: #ffffff;
  border-radius: 999px;
  background: linear-gradient(135deg, rgb(116, 235, 213) 0%, rgb(172, 182, 229) 100%);
  align-items: center;
  justify-content: center;
  transition: all 350ms cubic-bezier(0.77, 0, 0.175, 1);
}
.edit__page__button:hover, .edit__page__button:active, .edit__page__button:focus {
  text-decoration: none;
  color: #ffffff;
  transform: scale(1.15);
}

.cookie-banner {
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  z-index: 10;
}
.cookie-banner p {
  font-size: 14px;
  line-height: 125%;
}
.cookie-banner p a {
  color: #222222;
  text-decoration: underline;
}
.cookie-banner p a:hover {
  color: black;
}
.cookie-banner .button-wrap {
  min-width: 30%;
}
.cookie-banner .button {
  margin: 2px;
  margin-left: 12px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.cookie-banner .uk-button-primary {
  background-color: #18bbb9;
  color: #ffffff;
}
.cookie-banner .uk-button-primary:hover {
  background-color: #15a4a3;
}
.cookie-banner #cookie_form {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.cookie-banner #cookie_form .form-url {
  display: none;
}
.cookie-banner #cookie_form .cookie-banner-popup {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(34, 34, 34, 0.2);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px);
  max-width: 860px;
  width: 100%;
}
.cookie-banner #cookie_form .cookie-banner-popup__bottom {
  border-top: 1px solid #dddddd;
  padding: 10px 20px;
}
.cookie-banner #cookie_form .cookie-banner-popup__top {
  overflow: auto;
  padding: 20px;
  flex: 1;
}

@media only screen and (max-width: 961px) {
  .cookie-banner {
    text-align: center;
  }
  .cookie-banner p {
    font-size: 12px;
    text-align: center !important;
  }
  .cookie-banner .button {
    font-size: 12px;
  }
}
/*========================================================
=                       Utilities                        =
=========================================================*/
/* positioning & alignment
-------------------------------------------------- */
.push-down, .flex-grow {
  flex-grow: 1;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.pull-right--mini {
  margin-right: -1px;
}

.pull-bottom--mini {
  margin-bottom: -1px;
}

.ex-p-rel {
  position: relative;
}

.ex-p-abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ex-p-center {
  margin: auto;
  display: block;
}

.ex-wr-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* blend modes
-------------------------------------------------- */
.blend--multiply {
  mix-blend-mode: multiply;
}

/* Spacing
-------------------------------------------------- */
.margin-top-verthus {
  margin-top: 30px;
}

.ex-mt--0 {
  margin-top: 0px;
}

.ex-mr--0 {
  margin-right: 0px;
}

.ex-mb--0 {
  margin-bottom: 0px;
}

.ex-ml--0 {
  margin-left: 0px;
}

.ex-mt--5 {
  margin-top: 5px;
}

.ex-mr--5 {
  margin-right: 5px;
}

.ex-mb--5 {
  margin-bottom: 5px;
}

.ex-ml--5 {
  margin-left: 5px;
}

.ex-mt--10 {
  margin-top: 10px;
}

.ex-mr--10 {
  margin-right: 10px;
}

.ex-mb--10 {
  margin-bottom: 10px;
}

.ex-ml--10 {
  margin-left: 10px;
}

.ex-mt--15 {
  margin-top: 15px;
}

.ex-mr--15 {
  margin-right: 15px;
}

.ex-mb--15 {
  margin-bottom: 15px;
}

.ex-ml--15 {
  margin-left: 15px;
}

.ex-mt--20 {
  margin-top: 20px;
}

.ex-mr--20 {
  margin-right: 20px;
}

.ex-mb--20 {
  margin-bottom: 20px;
}

.ex-ml--20 {
  margin-left: 20px;
}

.ex-mt--25 {
  margin-top: 25px;
}

.ex-mr--25 {
  margin-right: 25px;
}

.ex-mb--25 {
  margin-bottom: 25px;
}

.ex-ml--25 {
  margin-left: 25px;
}

.ex-mt--30 {
  margin-top: 30px;
}

.ex-mr--30 {
  margin-right: 30px;
}

.ex-mb--30 {
  margin-bottom: 30px;
}

.ex-ml--30 {
  margin-left: 30px;
}

.ex-mt--35 {
  margin-top: 35px;
}

.ex-mr--35 {
  margin-right: 35px;
}

.ex-mb--35 {
  margin-bottom: 35px;
}

.ex-ml--35 {
  margin-left: 35px;
}

.ex-mt--40 {
  margin-top: 40px;
}

.ex-mr--40 {
  margin-right: 40px;
}

.ex-mb--40 {
  margin-bottom: 40px;
}

.ex-ml--40 {
  margin-left: 40px;
}

.ex-mt--45 {
  margin-top: 45px;
}

.ex-mr--45 {
  margin-right: 45px;
}

.ex-mb--45 {
  margin-bottom: 45px;
}

.ex-ml--45 {
  margin-left: 45px;
}

.ex-mt--50 {
  margin-top: 50px;
}

.ex-mr--50 {
  margin-right: 50px;
}

.ex-mb--50 {
  margin-bottom: 50px;
}

.ex-ml--50 {
  margin-left: 50px;
}

.ex-mt--55 {
  margin-top: 55px;
}

.ex-mr--55 {
  margin-right: 55px;
}

.ex-mb--55 {
  margin-bottom: 55px;
}

.ex-ml--55 {
  margin-left: 55px;
}

.ex-mt--60 {
  margin-top: 60px;
}

.ex-mr--60 {
  margin-right: 60px;
}

.ex-mb--60 {
  margin-bottom: 60px;
}

.ex-ml--60 {
  margin-left: 60px;
}

.ex-mt--65 {
  margin-top: 65px;
}

.ex-mr--65 {
  margin-right: 65px;
}

.ex-mb--65 {
  margin-bottom: 65px;
}

.ex-ml--65 {
  margin-left: 65px;
}

.ex-mt--70 {
  margin-top: 70px;
}

.ex-mr--70 {
  margin-right: 70px;
}

.ex-mb--70 {
  margin-bottom: 70px;
}

.ex-ml--70 {
  margin-left: 70px;
}

.ex-mt--75 {
  margin-top: 75px;
}

.ex-mr--75 {
  margin-right: 75px;
}

.ex-mb--75 {
  margin-bottom: 75px;
}

.ex-ml--75 {
  margin-left: 75px;
}

.ex-mt--80 {
  margin-top: 80px;
}

.ex-mr--80 {
  margin-right: 80px;
}

.ex-mb--80 {
  margin-bottom: 80px;
}

.ex-ml--80 {
  margin-left: 80px;
}

.ex-mt--85 {
  margin-top: 85px;
}

.ex-mr--85 {
  margin-right: 85px;
}

.ex-mb--85 {
  margin-bottom: 85px;
}

.ex-ml--85 {
  margin-left: 85px;
}

.ex-mt--90 {
  margin-top: 90px;
}

.ex-mr--90 {
  margin-right: 90px;
}

.ex-mb--90 {
  margin-bottom: 90px;
}

.ex-ml--90 {
  margin-left: 90px;
}

.ex-mt--95 {
  margin-top: 95px;
}

.ex-mr--95 {
  margin-right: 95px;
}

.ex-mb--95 {
  margin-bottom: 95px;
}

.ex-ml--95 {
  margin-left: 95px;
}

.ex-mt--100 {
  margin-top: 100px;
}

.ex-mr--100 {
  margin-right: 100px;
}

.ex-mb--100 {
  margin-bottom: 100px;
}

.ex-ml--100 {
  margin-left: 100px;
}

.ex-mt--105 {
  margin-top: 105px;
}

.ex-mr--105 {
  margin-right: 105px;
}

.ex-mb--105 {
  margin-bottom: 105px;
}

.ex-ml--105 {
  margin-left: 105px;
}

.ex-mt--110 {
  margin-top: 110px;
}

.ex-mr--110 {
  margin-right: 110px;
}

.ex-mb--110 {
  margin-bottom: 110px;
}

.ex-ml--110 {
  margin-left: 110px;
}

.ex-mt--115 {
  margin-top: 115px;
}

.ex-mr--115 {
  margin-right: 115px;
}

.ex-mb--115 {
  margin-bottom: 115px;
}

.ex-ml--115 {
  margin-left: 115px;
}

.ex-mt--120 {
  margin-top: 120px;
}

.ex-mr--120 {
  margin-right: 120px;
}

.ex-mb--120 {
  margin-bottom: 120px;
}

.ex-ml--120 {
  margin-left: 120px;
}

.ex-mt--125 {
  margin-top: 125px;
}

.ex-mr--125 {
  margin-right: 125px;
}

.ex-mb--125 {
  margin-bottom: 125px;
}

.ex-ml--125 {
  margin-left: 125px;
}

.ex-mt--130 {
  margin-top: 130px;
}

.ex-mr--130 {
  margin-right: 130px;
}

.ex-mb--130 {
  margin-bottom: 130px;
}

.ex-ml--130 {
  margin-left: 130px;
}

.ex-mt--135 {
  margin-top: 135px;
}

.ex-mr--135 {
  margin-right: 135px;
}

.ex-mb--135 {
  margin-bottom: 135px;
}

.ex-ml--135 {
  margin-left: 135px;
}

.ex-mt--140 {
  margin-top: 140px;
}

.ex-mr--140 {
  margin-right: 140px;
}

.ex-mb--140 {
  margin-bottom: 140px;
}

.ex-ml--140 {
  margin-left: 140px;
}

.ex-mt--145 {
  margin-top: 145px;
}

.ex-mr--145 {
  margin-right: 145px;
}

.ex-mb--145 {
  margin-bottom: 145px;
}

.ex-ml--145 {
  margin-left: 145px;
}

.ex-mt--150 {
  margin-top: 150px;
}

.ex-mr--150 {
  margin-right: 150px;
}

.ex-mb--150 {
  margin-bottom: 150px;
}

.ex-ml--150 {
  margin-left: 150px;
}

.ex-mt--155 {
  margin-top: 155px;
}

.ex-mr--155 {
  margin-right: 155px;
}

.ex-mb--155 {
  margin-bottom: 155px;
}

.ex-ml--155 {
  margin-left: 155px;
}

.ex-mt--160 {
  margin-top: 160px;
}

.ex-mr--160 {
  margin-right: 160px;
}

.ex-mb--160 {
  margin-bottom: 160px;
}

.ex-ml--160 {
  margin-left: 160px;
}

.ex-mt--165 {
  margin-top: 165px;
}

.ex-mr--165 {
  margin-right: 165px;
}

.ex-mb--165 {
  margin-bottom: 165px;
}

.ex-ml--165 {
  margin-left: 165px;
}

.ex-mt--170 {
  margin-top: 170px;
}

.ex-mr--170 {
  margin-right: 170px;
}

.ex-mb--170 {
  margin-bottom: 170px;
}

.ex-ml--170 {
  margin-left: 170px;
}

.ex-mt--175 {
  margin-top: 175px;
}

.ex-mr--175 {
  margin-right: 175px;
}

.ex-mb--175 {
  margin-bottom: 175px;
}

.ex-ml--175 {
  margin-left: 175px;
}

.ex-mt--180 {
  margin-top: 180px;
}

.ex-mr--180 {
  margin-right: 180px;
}

.ex-mb--180 {
  margin-bottom: 180px;
}

.ex-ml--180 {
  margin-left: 180px;
}

.ex-mt--185 {
  margin-top: 185px;
}

.ex-mr--185 {
  margin-right: 185px;
}

.ex-mb--185 {
  margin-bottom: 185px;
}

.ex-ml--185 {
  margin-left: 185px;
}

.ex-mt--190 {
  margin-top: 190px;
}

.ex-mr--190 {
  margin-right: 190px;
}

.ex-mb--190 {
  margin-bottom: 190px;
}

.ex-ml--190 {
  margin-left: 190px;
}

.ex-mt--195 {
  margin-top: 195px;
}

.ex-mr--195 {
  margin-right: 195px;
}

.ex-mb--195 {
  margin-bottom: 195px;
}

.ex-ml--195 {
  margin-left: 195px;
}

.ex-mt--200 {
  margin-top: 200px;
}

.ex-mr--200 {
  margin-right: 200px;
}

.ex-mb--200 {
  margin-bottom: 200px;
}

.ex-ml--200 {
  margin-left: 200px;
}

.ex-pt--0 {
  padding-top: 0px;
}

.ex-pr--0 {
  padding-right: 0px;
}

.ex-pb--0 {
  padding-bottom: 0px;
}

.ex-pl--0 {
  padding-left: 0px;
}

.ex-pt--5 {
  padding-top: 5px;
}

.ex-pr--5 {
  padding-right: 5px;
}

.ex-pb--5 {
  padding-bottom: 5px;
}

.ex-pl--5 {
  padding-left: 5px;
}

.ex-pt--10 {
  padding-top: 10px;
}

.ex-pr--10 {
  padding-right: 10px;
}

.ex-pb--10 {
  padding-bottom: 10px;
}

.ex-pl--10 {
  padding-left: 10px;
}

.ex-pt--15 {
  padding-top: 15px;
}

.ex-pr--15 {
  padding-right: 15px;
}

.ex-pb--15 {
  padding-bottom: 15px;
}

.ex-pl--15 {
  padding-left: 15px;
}

.ex-pt--20 {
  padding-top: 20px;
}

.ex-pr--20 {
  padding-right: 20px;
}

.ex-pb--20 {
  padding-bottom: 20px;
}

.ex-pl--20 {
  padding-left: 20px;
}

.ex-pt--25 {
  padding-top: 25px;
}

.ex-pr--25 {
  padding-right: 25px;
}

.ex-pb--25 {
  padding-bottom: 25px;
}

.ex-pl--25 {
  padding-left: 25px;
}

.ex-pt--30 {
  padding-top: 30px;
}

.ex-pr--30 {
  padding-right: 30px;
}

.ex-pb--30 {
  padding-bottom: 30px;
}

.ex-pl--30 {
  padding-left: 30px;
}

.ex-pt--35 {
  padding-top: 35px;
}

.ex-pr--35 {
  padding-right: 35px;
}

.ex-pb--35 {
  padding-bottom: 35px;
}

.ex-pl--35 {
  padding-left: 35px;
}

.ex-pt--40 {
  padding-top: 40px;
}

.ex-pr--40 {
  padding-right: 40px;
}

.ex-pb--40 {
  padding-bottom: 40px;
}

.ex-pl--40 {
  padding-left: 40px;
}

.ex-pt--45 {
  padding-top: 45px;
}

.ex-pr--45 {
  padding-right: 45px;
}

.ex-pb--45 {
  padding-bottom: 45px;
}

.ex-pl--45 {
  padding-left: 45px;
}

.ex-pt--50 {
  padding-top: 50px;
}

.ex-pr--50 {
  padding-right: 50px;
}

.ex-pb--50 {
  padding-bottom: 50px;
}

.ex-pl--50 {
  padding-left: 50px;
}

.ex-pt--55 {
  padding-top: 55px;
}

.ex-pr--55 {
  padding-right: 55px;
}

.ex-pb--55 {
  padding-bottom: 55px;
}

.ex-pl--55 {
  padding-left: 55px;
}

.ex-pt--60 {
  padding-top: 60px;
}

.ex-pr--60 {
  padding-right: 60px;
}

.ex-pb--60 {
  padding-bottom: 60px;
}

.ex-pl--60 {
  padding-left: 60px;
}

.ex-pt--65 {
  padding-top: 65px;
}

.ex-pr--65 {
  padding-right: 65px;
}

.ex-pb--65 {
  padding-bottom: 65px;
}

.ex-pl--65 {
  padding-left: 65px;
}

.ex-pt--70 {
  padding-top: 70px;
}

.ex-pr--70 {
  padding-right: 70px;
}

.ex-pb--70 {
  padding-bottom: 70px;
}

.ex-pl--70 {
  padding-left: 70px;
}

.ex-pt--75 {
  padding-top: 75px;
}

.ex-pr--75 {
  padding-right: 75px;
}

.ex-pb--75 {
  padding-bottom: 75px;
}

.ex-pl--75 {
  padding-left: 75px;
}

.ex-pt--80 {
  padding-top: 80px;
}

.ex-pr--80 {
  padding-right: 80px;
}

.ex-pb--80 {
  padding-bottom: 80px;
}

.ex-pl--80 {
  padding-left: 80px;
}

.ex-pt--85 {
  padding-top: 85px;
}

.ex-pr--85 {
  padding-right: 85px;
}

.ex-pb--85 {
  padding-bottom: 85px;
}

.ex-pl--85 {
  padding-left: 85px;
}

.ex-pt--90 {
  padding-top: 90px;
}

.ex-pr--90 {
  padding-right: 90px;
}

.ex-pb--90 {
  padding-bottom: 90px;
}

.ex-pl--90 {
  padding-left: 90px;
}

.ex-pt--95 {
  padding-top: 95px;
}

.ex-pr--95 {
  padding-right: 95px;
}

.ex-pb--95 {
  padding-bottom: 95px;
}

.ex-pl--95 {
  padding-left: 95px;
}

.ex-pt--100 {
  padding-top: 100px;
}

.ex-pr--100 {
  padding-right: 100px;
}

.ex-pb--100 {
  padding-bottom: 100px;
}

.ex-pl--100 {
  padding-left: 100px;
}

.ex-pt--105 {
  padding-top: 105px;
}

.ex-pr--105 {
  padding-right: 105px;
}

.ex-pb--105 {
  padding-bottom: 105px;
}

.ex-pl--105 {
  padding-left: 105px;
}

.ex-pt--110 {
  padding-top: 110px;
}

.ex-pr--110 {
  padding-right: 110px;
}

.ex-pb--110 {
  padding-bottom: 110px;
}

.ex-pl--110 {
  padding-left: 110px;
}

.ex-pt--115 {
  padding-top: 115px;
}

.ex-pr--115 {
  padding-right: 115px;
}

.ex-pb--115 {
  padding-bottom: 115px;
}

.ex-pl--115 {
  padding-left: 115px;
}

.ex-pt--120 {
  padding-top: 120px;
}

.ex-pr--120 {
  padding-right: 120px;
}

.ex-pb--120 {
  padding-bottom: 120px;
}

.ex-pl--120 {
  padding-left: 120px;
}

.ex-pt--125 {
  padding-top: 125px;
}

.ex-pr--125 {
  padding-right: 125px;
}

.ex-pb--125 {
  padding-bottom: 125px;
}

.ex-pl--125 {
  padding-left: 125px;
}

.ex-pt--130 {
  padding-top: 130px;
}

.ex-pr--130 {
  padding-right: 130px;
}

.ex-pb--130 {
  padding-bottom: 130px;
}

.ex-pl--130 {
  padding-left: 130px;
}

.ex-pt--135 {
  padding-top: 135px;
}

.ex-pr--135 {
  padding-right: 135px;
}

.ex-pb--135 {
  padding-bottom: 135px;
}

.ex-pl--135 {
  padding-left: 135px;
}

.ex-pt--140 {
  padding-top: 140px;
}

.ex-pr--140 {
  padding-right: 140px;
}

.ex-pb--140 {
  padding-bottom: 140px;
}

.ex-pl--140 {
  padding-left: 140px;
}

.ex-pt--145 {
  padding-top: 145px;
}

.ex-pr--145 {
  padding-right: 145px;
}

.ex-pb--145 {
  padding-bottom: 145px;
}

.ex-pl--145 {
  padding-left: 145px;
}

.ex-pt--150 {
  padding-top: 150px;
}

.ex-pr--150 {
  padding-right: 150px;
}

.ex-pb--150 {
  padding-bottom: 150px;
}

.ex-pl--150 {
  padding-left: 150px;
}

.ex-pt--155 {
  padding-top: 155px;
}

.ex-pr--155 {
  padding-right: 155px;
}

.ex-pb--155 {
  padding-bottom: 155px;
}

.ex-pl--155 {
  padding-left: 155px;
}

.ex-pt--160 {
  padding-top: 160px;
}

.ex-pr--160 {
  padding-right: 160px;
}

.ex-pb--160 {
  padding-bottom: 160px;
}

.ex-pl--160 {
  padding-left: 160px;
}

.ex-pt--165 {
  padding-top: 165px;
}

.ex-pr--165 {
  padding-right: 165px;
}

.ex-pb--165 {
  padding-bottom: 165px;
}

.ex-pl--165 {
  padding-left: 165px;
}

.ex-pt--170 {
  padding-top: 170px;
}

.ex-pr--170 {
  padding-right: 170px;
}

.ex-pb--170 {
  padding-bottom: 170px;
}

.ex-pl--170 {
  padding-left: 170px;
}

.ex-pt--175 {
  padding-top: 175px;
}

.ex-pr--175 {
  padding-right: 175px;
}

.ex-pb--175 {
  padding-bottom: 175px;
}

.ex-pl--175 {
  padding-left: 175px;
}

.ex-pt--180 {
  padding-top: 180px;
}

.ex-pr--180 {
  padding-right: 180px;
}

.ex-pb--180 {
  padding-bottom: 180px;
}

.ex-pl--180 {
  padding-left: 180px;
}

.ex-pt--185 {
  padding-top: 185px;
}

.ex-pr--185 {
  padding-right: 185px;
}

.ex-pb--185 {
  padding-bottom: 185px;
}

.ex-pl--185 {
  padding-left: 185px;
}

.ex-pt--190 {
  padding-top: 190px;
}

.ex-pr--190 {
  padding-right: 190px;
}

.ex-pb--190 {
  padding-bottom: 190px;
}

.ex-pl--190 {
  padding-left: 190px;
}

.ex-pt--195 {
  padding-top: 195px;
}

.ex-pr--195 {
  padding-right: 195px;
}

.ex-pb--195 {
  padding-bottom: 195px;
}

.ex-pl--195 {
  padding-left: 195px;
}

.ex-pt--200 {
  padding-top: 200px;
}

.ex-pr--200 {
  padding-right: 200px;
}

.ex-pb--200 {
  padding-bottom: 200px;
}

.ex-pl--200 {
  padding-left: 200px;
}

/* Sizing
-------------------------------------------------- */
.ex-w-15 {
  width: 15%;
}

.ex-w-25 {
  width: 25%;
}

.ex-w-50 {
  width: 50%;
}

.ex-w-75 {
  width: 75%;
}

.ex-w-100 {
  width: 100%;
}

.ex-h-100 {
  height: 100%;
}

/* Border
-------------------------------------------------- */
.ex-br-circle {
  border-radius: 99999px;
}

.ex-br-top {
  border-radius: 25px 25px 0 0;
}

.ex-br-25 {
  border-radius: 25px;
}

hr {
  border-top: 1px solid #e8e8e8;
}

/* Backgrounds
-------------------------------------------------- */
.bg--primary {
  background: #18bbb9;
}
.bg--secondary {
  background: #e7f8f8;
}

/* Hover
-------------------------------------------------- */
.no-hover:hover {
  text-decoration: none;
}

@media (max-width: 960px) {
  .modal {
    padding: 60px 0;
  }
  .modal__box {
    max-width: 95vw;
    padding: 30px;
  }

  .modal--big .modal__box {
    width: 95%;
  }
  .modal--big .product-row {
    display: initial;
  }
  .modal--big .product-row .image-block {
    padding-top: 50%;
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 640px) {
  .uk-flex\@s {
    display: flex !important;
  }
}
@media (max-width: 640px) {
  .modal--big {
    padding: 40px 0;
  }
  .modal--big .modal--title {
    font-size: 2rem;
    max-width: 100%;
  }
  .modal--big .product-added-wrap {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
}
/*------------------------------------*\
  #COMPONENTS SHOP
\*------------------------------------*/
.active-filter {
  display: flex;
  align-items: baseline;
  padding: 8px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}
.active-filter span {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
.active-filter i,
.active-filter svg {
  font-size: 12px;
  color: #666666;
  margin-left: 5px;
}
.active-filter:hover {
  cursor: pointer;
}
.active-filter:hover i,
.active-filter:hover svg {
  color: #ff2424;
}

.add-to-cart .uk-offcanvas-bar {
  width: 500px;
  left: -500px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
  padding: 60px;
}
.add-to-cart.uk-open .uk-offcanvas-bar {
  left: 0;
}

@media (max-width: 640px) {
  .add-to-cart .uk-offcanvas-bar {
    padding: 25px;
    width: 100vw;
    left: -100vw;
  }
}
.address-box {
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.address-box > section {
  flex: 1 0 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.address-box > section a {
  text-decoration: underline;
  color: #18bbb9;
  margin-bottom: 6px;
}
.address-box > section a:hover {
  color: #139795;
  text-decoration: none;
}
.address-box > section a.disabled {
  color: #666666;
  text-decoration: none;
}
.address-box > section a.disabled:hover {
  cursor: not-allowed;
}
.address-box > section a.no-click {
  color: #666666;
  text-decoration: none;
}
.address-box > section a.no-click:hover {
  cursor: default;
}
.address-box > section a:last-of-type {
  margin-bottom: 0;
}

.uk-breadcrumb li a {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-breadcrumb li a:hover {
  color: #18bbb9;
}
.uk-breadcrumb li span {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: ">";
  color: #e0e0e0;
  margin: 0px 15px;
}

.uk-button-cart {
  background-color: #1eaf3a;
  color: #ffffff;
}
.uk-button-cart:hover {
  background-color: #188c2e;
}

.uk-button-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #18bbb9;
}
.uk-button-back:hover {
  text-decoration: none;
  color: #000;
}

.big-button {
  padding: 45px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
  color: #222222;
}
.big-button:hover {
  text-decoration: none;
  border: 1px solid #18bbb9;
  background-color: #e7f8f8;
  color: #18bbb9;
}
.big-button.uk-active {
  background-color: #18bbb9;
  border: 1px solid #18bbb9;
  color: #ffffff;
}

.cart-dropdown {
  width: 480px;
  padding: 24px 30px 30px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}
.cart-dropdown h6 {
  font-weight: bold;
  font-size: 18px;
  color: #222222;
  margin-bottom: 11px;
}
.cart-dropdown__row {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 30px 30px;
  width: 100%;
  align-content: flex-start;
  border-bottom: 1px solid #e8e8e8;
  padding: 14px 0px;
}
.cart-dropdown__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e7f8f8;
}
.cart-dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}
.cart-dropdown__title, .cart-dropdown__price {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-right: 20px;
}
.cart-dropdown__price {
  flex: 1 0 auto;
  white-space: nowrap;
}
.cart-dropdown__row:hover {
  text-decoration: none;
}
.cart-dropdown__row:hover .cart-dropdown__title,
.cart-dropdown__row:hover .cart-dropdown__price {
  color: #18bbb9;
}

.cart {
  overflow: hidden;
}
.cart__row {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 0;
  display: grid;
  grid-template-columns: 100px 3fr 1fr 1fr;
  grid-template-areas: "remove link amount price";
  column-gap: 10px;
  row-gap: 10px;
}
.cart__row:last-of-type {
  border-bottom: 0;
}
.cart__row .cga-remove {
  grid-area: remove;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart__row .cga-link {
  grid-area: link;
}
.cart__row .cga-amount {
  grid-area: amount;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cart__row .cga-price {
  grid-area: price;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.cart__remove {
  border: 0;
  background-color: transparent;
  padding: 0;
  font-size: 21px;
  color: #e8e8e8;
  cursor: pointer;
}
.cart__remove:hover {
  color: #ff2424;
}
.cart__image {
  position: relative;
}
.cart__product-info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cart__product-info h1, .cart__product-info .uk-h1,
.cart__product-info h2, .cart__product-info .uk-h2,
.cart__product-info h3, .cart__product-info .uk-h3,
.cart__product-info h4, .cart__product-info .uk-h4,
.cart__product-info h5, .cart__product-info .uk-h5,
.cart__product-info p, .cart__product-info .cms, .cart__product-info .text {
  transition: all 0.3s ease;
  color: #222222;
}
.cart__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart__link:hover, .cart__link:active, .cart__link:focus {
  text-decoration: none;
}
.cart__link:hover .cart__product-info h1, .cart__link:hover .cart__product-info .uk-h1,
.cart__link:hover .cart__product-info h2, .cart__link:hover .cart__product-info .uk-h2,
.cart__link:hover .cart__product-info h3, .cart__link:hover .cart__product-info .uk-h3,
.cart__link:hover .cart__product-info h4, .cart__link:hover .cart__product-info .uk-h4,
.cart__link:hover .cart__product-info h5, .cart__link:hover .cart__product-info .uk-h5,
.cart__link:hover .cart__product-info p, .cart__link:hover .cart__product-info .cms, .cart__link:hover .cart__product-info .text, .cart__link:active .cart__product-info h1, .cart__link:active .cart__product-info .uk-h1,
.cart__link:active .cart__product-info h2, .cart__link:active .cart__product-info .uk-h2,
.cart__link:active .cart__product-info h3, .cart__link:active .cart__product-info .uk-h3,
.cart__link:active .cart__product-info h4, .cart__link:active .cart__product-info .uk-h4,
.cart__link:active .cart__product-info h5, .cart__link:active .cart__product-info .uk-h5,
.cart__link:active .cart__product-info p, .cart__link:active .cart__product-info .cms, .cart__link:active .cart__product-info .text, .cart__link:focus .cart__product-info h1, .cart__link:focus .cart__product-info .uk-h1,
.cart__link:focus .cart__product-info h2, .cart__link:focus .cart__product-info .uk-h2,
.cart__link:focus .cart__product-info h3, .cart__link:focus .cart__product-info .uk-h3,
.cart__link:focus .cart__product-info h4, .cart__link:focus .cart__product-info .uk-h4,
.cart__link:focus .cart__product-info h5, .cart__link:focus .cart__product-info .uk-h5,
.cart__link:focus .cart__product-info p, .cart__link:focus .cart__product-info .cms, .cart__link:focus .cart__product-info .text {
  color: #18bbb9;
}
.cart__link.no-hover:hover, .cart__link.no-hover:active, .cart__link.no-hover:focus {
  text-decoration: none;
}
.cart__link.no-hover:hover .cart__product-info h1, .cart__link.no-hover:hover .cart__product-info .uk-h1,
.cart__link.no-hover:hover .cart__product-info h2, .cart__link.no-hover:hover .cart__product-info .uk-h2,
.cart__link.no-hover:hover .cart__product-info h3, .cart__link.no-hover:hover .cart__product-info .uk-h3,
.cart__link.no-hover:hover .cart__product-info h4, .cart__link.no-hover:hover .cart__product-info .uk-h4,
.cart__link.no-hover:hover .cart__product-info h5, .cart__link.no-hover:hover .cart__product-info .uk-h5,
.cart__link.no-hover:hover .cart__product-info p, .cart__link.no-hover:hover .cart__product-info .cms, .cart__link.no-hover:hover .cart__product-info .text, .cart__link.no-hover:active .cart__product-info h1, .cart__link.no-hover:active .cart__product-info .uk-h1,
.cart__link.no-hover:active .cart__product-info h2, .cart__link.no-hover:active .cart__product-info .uk-h2,
.cart__link.no-hover:active .cart__product-info h3, .cart__link.no-hover:active .cart__product-info .uk-h3,
.cart__link.no-hover:active .cart__product-info h4, .cart__link.no-hover:active .cart__product-info .uk-h4,
.cart__link.no-hover:active .cart__product-info h5, .cart__link.no-hover:active .cart__product-info .uk-h5,
.cart__link.no-hover:active .cart__product-info p, .cart__link.no-hover:active .cart__product-info .cms, .cart__link.no-hover:active .cart__product-info .text, .cart__link.no-hover:focus .cart__product-info h1, .cart__link.no-hover:focus .cart__product-info .uk-h1,
.cart__link.no-hover:focus .cart__product-info h2, .cart__link.no-hover:focus .cart__product-info .uk-h2,
.cart__link.no-hover:focus .cart__product-info h3, .cart__link.no-hover:focus .cart__product-info .uk-h3,
.cart__link.no-hover:focus .cart__product-info h4, .cart__link.no-hover:focus .cart__product-info .uk-h4,
.cart__link.no-hover:focus .cart__product-info h5, .cart__link.no-hover:focus .cart__product-info .uk-h5,
.cart__link.no-hover:focus .cart__product-info p, .cart__link.no-hover:focus .cart__product-info .cms, .cart__link.no-hover:focus .cart__product-info .text {
  color: #222222;
}
.cart__amount {
  height: 50px;
  width: 65px;
  border: 1px solid #e8e8e8;
  color: #222222;
  border-radius: 3px;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #222222;
}
.cart__amount--danger {
  border: 1px solid #ff2424;
  color: #ff2424;
}
.cart__amount:focus {
  border: 1px solid #18bbb9;
  outline: none;
}
.cart__prices {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.cart__prices--current {
  margin: 0 10px 0 0;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #222222;
}
.cart__prices--label {
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #222222;
}
.cart__prices--old {
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #222222;
  text-decoration: line-through;
}
.cart__footer {
  padding: 60px 0;
}
.cart__footer select {
  width: 270px;
}
.cart__totals {
  display: grid;
  grid-template-columns: 3.2fr 1.3fr;
  gap: 12px 30px;
}
.cart__totals__label {
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.cart__totals__label.total {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #18bbb9;
}
.cart__totals__price {
  text-align: right;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #222222;
  margin: 0;
  white-space: nowrap;
}
.cart__totals__price.delivery-cost {
  color: #18bbb9;
}
.cart__totals__price.total {
  font-size: 32px;
  line-height: 38px;
  color: #18bbb9;
}

@media (max-width: 1200px) {
  .cart__totals {
    grid-template-columns: 2.5fr 2fr;
  }
}
@media (max-width: 960px) {
  .cart__row {
    grid-template-columns: 40px 3fr 1fr 1fr;
  }
  .cart__totals {
    grid-template-columns: 1fr 1fr;
  }
  .cart__totals h3 {
    padding-right: 10px;
  }
}
@media (max-width: 640px) {
  .cart__row {
    grid-template-columns: 40px 1fr 1fr;
    grid-template-areas: "remove link link" "remove amount price";
    row-gap: 20px;
  }
  .cart__footer {
    padding: 20px 0;
  }
  .cart__totals {
    grid-template-columns: 1.5fr 1fr;
    gap: 16px 30px;
  }
  .cart__totals h3 {
    padding-right: 0px;
  }
}
.check-list {
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
}
.check-list li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.check-list li i,
.check-list li svg {
  flex: 0 0 20px;
  margin-right: 10px;
}

.checkout-footer {
  padding: 0;
  background-color: #e7f8f8;
}
.checkout-footer .checkout-footer__inner {
  padding: 60px 60px 30px 60px;
}
.checkout-footer .checkout-footer__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.checkout-footer .checkout-footer__nav li {
  margin: 0px 12px;
}

@media (max-width: 640px) {
  .checkout-footer .checkout-footer__inner {
    padding: 20px;
  }
  .checkout-footer .checkout-footer__nav {
    flex-flow: column;
  }
}
.checkout-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.checkout-nav li {
  margin-right: 30px;
}
.checkout-nav li a {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.checkout-nav li a img,
.checkout-nav li a svg {
  margin-bottom: 10px;
}
.checkout-nav li a:hover {
  text-decoration: none;
  color: #18bbb9;
}
.checkout-nav li:last-of-type {
  margin-right: 0;
}

.checkout-steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: ". . . . .";
  border-radius: 3px;
  overflow: hidden;
  padding-right: 16px;
}
.checkout-steps .checkout-step {
  flex: 1 0 auto;
  background-color: #18bbb9;
  padding: 12px 0px 12px 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.checkout-steps .checkout-step:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #18bbb9;
  border-width: 24px;
  margin-top: -24px;
}
.checkout-steps .checkout-step:after {
  left: 0%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ffffff;
  border-width: 24px;
  margin-top: -24px;
}
.checkout-steps .checkout-step.uk-active {
  color: #ffffff;
  background-color: #18bbb9;
}
.checkout-steps .checkout-step.uk-active:before {
  border-left-color: #18bbb9;
}
.checkout-steps .checkout-step.uk-past {
  color: #ffffff;
  background-color: #e7f8f8;
}
.checkout-steps .checkout-step.uk-past:before {
  border-left-color: #e7f8f8;
}
.checkout-steps .checkout-step:nth-child(1) {
  z-index: 5;
}
.checkout-steps .checkout-step:nth-child(1):after {
  display: none;
}
.checkout-steps .checkout-step:nth-child(2) {
  z-index: 4;
}
.checkout-steps .checkout-step:nth-child(3) {
  z-index: 3;
}
.checkout-steps .checkout-step:nth-child(4) {
  z-index: 2;
}
.checkout-steps .checkout-step:nth-child(5) {
  z-index: 1;
}
.checkout-steps .checkout-step:nth-child(5):before {
  display: none;
}

@media (max-width: 640px) {
  .checkout-steps {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: "." "." "." ".";
    grid-gap: 8px;
    padding-right: 0px;
  }
  .checkout-steps .checkout-step {
    justify-content: flex-start;
  }
  .checkout-steps .checkout-step:before {
    display: none;
  }
  .checkout-steps .checkout-step:after {
    display: none;
  }
}
.color-picker {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 5px 5px;
}
.color-picker a {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background-color: var(--bg-color);
  border: 2px solid #e8e8e8;
}
.color-picker a:hover {
  border: 2px solid #e7f8f8;
}
.color-picker a.active {
  border: 2px solid #18bbb9;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}
.color-picker a.disabled {
  background: repeating-linear-gradient(45deg, var(--bg-color), var(--bg-color) 2px, #e8e8e8 2px, #e8e8e8 4px);
}
.color-picker a.disabled:hover {
  cursor: no-drop;
}
.color-picker--wide {
  grid-template-columns: repeat(16, 1fr);
}

@media (max-width: 640px) {
  .color-picker {
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
  }
}
.coupon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 270px;
  position: relative;
}
.coupon__input {
  width: 100%;
  height: 50px;
  border-radius: 3px 0 0 3px;
  border: 1px solid #e8e8e8;
  border-right: 0;
  padding: 0px 20px;
  font-family: "sofia-pro";
  font-size: 16px;
  color: #222222;
  font-weight: 300;
}
.coupon__input::placeholder {
  font-style: italic;
  color: #666666;
}
.coupon__input:focus {
  border: 1px solid #18bbb9;
  outline: none;
}
.coupon__submit {
  height: 50px;
  width: 50px;
  flex: 0 0 50px;
  border-radius: 0 3px 3px 0;
  border: 0;
  background-color: #18bbb9;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}
.coupon__submit:hover, .coupon__submit:active, .coupon__submit:focus {
  background-color: #15a09e;
}

.delivery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px 10px;
}
.delivery__existing {
  border: 1px solid #e8e8e8;
  background-color: transparent;
  border-radius: 3px;
  padding: 30px;
  text-align: left;
  cursor: pointer;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.delivery__existing:hover {
  background-color: #e7f8f8;
  border: 1px solid #18bbb9;
  color: #18bbb9 !important;
}
.delivery__existing.active {
  box-shadow: inset 0 0 0 2px #18bbb9;
  border: 1px solid #18bbb9;
  background-color: #e7f8f8;
}
.delivery__new {
  position: relative;
  background-color: #e7f8f8;
  border: 0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  cursor: pointer;
}
.delivery__new i,
.delivery__new svg {
  font-size: 14px;
}
.delivery__new.active {
  background-color: #18bbb9;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.delivery__new.active::after {
  display: block;
}
.delivery__new::after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #18bbb9;
  border-width: 10px;
  margin-left: -10px;
  display: none;
}
.delivery__new:hover {
  background-color: #18bbb9;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.delivery__new:hover::after {
  display: block;
}

#new-delivery, #new-billing {
  border: 1px solid #e8e8e8;
  margin-top: 30px;
  margin-left: 0;
  padding: 23px 30px 30px 15px;
}

@media (max-width: 960px) {
  .delivery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .delivery {
    grid-template-columns: repeat(1, 1fr);
  }
  .delivery__new {
    height: 80px;
  }
}
.footer-bar {
  background-color: #18bbb9;
  padding: 40px 0;
}
.footer-bar * {
  color: #ffffff !important;
}
.footer-bar * p:last-child {
  margin-bottom: 0;
}

.image-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #18bbb9;
  color: #ffffff;
  padding: 6px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  border-radius: 0 3px 0 0;
}
.image-banner--detail {
  font-size: 18px;
  line-height: 27px;
  padding: 16px 20px;
}

.info--circle {
  color: #18bbb9 !important;
  transform: rotate(360deg) !important;
  font-size: 16px !important;
}
.info--modal h2 {
  color: #18bbb9;
}

.link-sidebar {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.link-sidebar li {
  margin-bottom: 8px;
}
.link-sidebar li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
.link-sidebar li a:hover, .link-sidebar li a:active, .link-sidebar li a:focus {
  color: #139795;
  text-decoration: underline;
}
.link-sidebar li a.uk-active, .link-sidebar li a.active {
  color: #18bbb9;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}
.link-sidebar li a.uk-active:hover, .link-sidebar li a.uk-active:active, .link-sidebar li a.uk-active:focus, .link-sidebar li a.active:hover, .link-sidebar li a.active:active, .link-sidebar li a.active:focus {
  color: #139795;
}
.link-sidebar li.top-category {
  margin-bottom: 30px;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #222222;
}
.link-sidebar li.top-category a {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.link-sidebar li.top-category a:hover, .link-sidebar li.top-category a:active, .link-sidebar li.top-category a:focus {
  text-decoration: none;
}

.uk-notification {
  min-width: 490px;
  z-index: 3000;
}
.uk-notification .uk-notification-message {
  border: 1px solid #18bbb9;
  background-color: #ffffff;
  min-width: 490px;
  padding: 25px 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  color: #18bbb9;
}
.uk-notification .uk-notification-message-danger {
  background-color: #ff2424;
  color: #ffffff;
  border: 1px solid #ff2424;
}
.uk-notification .uk-notification-message .uk-notification-close {
  top: 35px;
  right: 20px;
}

@media (max-width: 640px) {
  .uk-notification {
    min-width: calc(100vw - 24px);
  }
  .uk-notification .uk-notification-message {
    min-width: calc(100vw - 24px);
    padding: 15px 20px;
    font-size: 16px;
    line-height: 21px;
  }
}
.order {
  padding: 30px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 30px 30px;
}
.order__right {
  display: grid;
  gap: 30px 30px;
  grid-template-columns: 1fr 1fr;
}
.order__item {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr 1.5fr;
  align-items: start;
}
.order__item .image-block {
  background-size: contain;
}

.uk-pagination li {
  padding-left: 5px;
}
.uk-pagination li > * {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #222222;
}
.uk-pagination li > a:hover {
  background-color: #18bbb9;
  border: 1px solid #18bbb9;
  color: #ffffff;
}
.uk-pagination li.uk-active > * {
  font-weight: bold;
  background-color: #18bbb9;
  color: #ffffff;
  border: 1px solid #18bbb9;
}

@media (max-width: 640px) {
  .uk-pagination li > * {
    width: 35px;
    height: 35px;
  }
}
.payment-method {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.perk-bar {
  background-color: #18bbb9;
  padding: 15px 0;
}
.perk-bar * {
  color: #ffffff;
}

.product {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}
.product__header {
  width: 100%;
  position: relative;
}
.product__like {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #18bbb9;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 3px;
  border: 0;
  padding: 6px;
}
.product__like > .normal {
  display: block;
}
.product__like > .active {
  display: none;
}
.product__like:hover > .normal, .product__like--liked > .normal {
  display: none;
}
.product__like:hover > .active, .product__like--liked > .active {
  display: block;
}
.product__info {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}
.product__title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  color: #222222;
}
.product__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.product__price {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.product__price--label {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #222222;
  margin: 0 10px 0 0;
}
.product__price--current {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: #222222;
  margin: 0 10px 0 0;
}
.product__price--old {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  text-decoration: line-through;
  margin: 0;
}
.product__cart {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  background-color: #1eaf3a;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: 20px;
  border-radius: 3px;
  cursor: pointer;
}
.product__cart:hover {
  background-color: #1b9e34;
}
.product:hover, .product:active, .product:focus {
  text-decoration: none;
}
.product:hover .product__title, .product:active .product__title, .product:focus .product__title {
  color: #18bbb9;
}

.product__stock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product__stock .icon {
  color: #ffffff;
  font-size: 14px;
  margin-right: 10px;
}
.product__stock p {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.product__stock--success .icon {
  color: #1eaf3a;
}
.product__stock--success p {
  color: #1eaf3a;
}
.product__stock--warning .icon {
  color: #ff6601;
}
.product__stock--warning p {
  color: #ff6601;
}
.product__stock--error .icon {
  color: #ff2424;
}
.product__stock--error p {
  color: #ff2424;
}

.product-gallery {
  border: 1px solid #e8e8e8;
}
.product-gallery__image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
}
.product-gallery__nav {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-template-areas: ". . . . .";
  margin-top: 30px;
}
.product-gallery__nav .product-gallery__nav--image {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
  height: 100px;
  cursor: pointer;
  transition: border 0.15s ease-in-out;
}
.product-gallery__nav .product-gallery__nav--image > img {
  max-height: 100%;
}
.product-gallery__nav .product-gallery__nav--image:hover {
  border: 3px solid #18bbb9;
}
.product-gallery__nav .product-gallery__nav--image.uk-active {
  border: 1px solid #18bbb9;
  border-radius: 0 !important;
}
.product-gallery__nav .product-gallery__nav--plus {
  border: 0;
  background-color: #18bbb9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 21px;
  cursor: pointer;
}
.product-gallery__nav .product-gallery__nav--plus:hover {
  background-color: #149b9a;
}

@media (max-width: 640px) {
  .product-gallery__nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px 10px;
    grid-template-areas: ". . ." ". . .";
  }
}
@media all and (-ms-high-contrast: none) {
  .product-gallery__nav {
    display: -ms-grid;
    -ms-grid-columns: repeat(5, 1fr);
    -ms-grid-rows: 1fr;
  }
}
.product-row {
  display: grid;
  grid-template-columns: 1fr 2fr 0.75fr 1.5fr;
  gap: 30px 30px;
}
.product-row .image-block {
  background-size: contain;
}

@media (max-width: 960px) {
  .product-row {
    display: initial;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". ." ". .";
  }
  .product-row .image-block {
    padding-top: 50%;
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 640px) {
  .product-row {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-areas: "." "." "." ".";
    align-items: start;
  }
}
.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 40px;
}
.products-header .custom-select {
  margin-bottom: -1px;
  border-radius: 3px 3px 0 0;
}

@media (max-width: 960px) {
  .products-header {
    flex-flow: column;
    align-items: stretch;
    border-bottom: 0;
  }
}
.search-bar {
  width: 100%;
  position: relative;
}
.search-bar__search {
  position: relative;
  width: 100%;
}
.search-bar__search input[type=text],
.search-bar__search input[type=search] {
  width: 100%;
  height: 50px;
  padding: 0 70px 0 20px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #222222;
}
.search-bar__search input[type=text]::placeholder,
.search-bar__search input[type=search]::placeholder {
  color: #222222;
}
.search-bar__search input[type=text]:focus,
.search-bar__search input[type=search]:focus {
  color: #18bbb9;
  border: 1px solid #18bbb9;
  outline: none;
}
.search-bar__search button[type=submit] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  color: #222222;
  cursor: pointer;
}
.search-bar__search button[type=submit]:hover {
  color: #18bbb9;
}
.search-bar__search-results {
  position: absolute;
  top: 54px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  width: 100%;
  border-radius: 0 0 4px 4px;
  padding: 30px;
  margin: 0;
  list-style-type: none;
  transform: translateY(15px);
  opacity: 0;
  z-index: -9999;
  transition: transform 0.4s ease, opacity 0.2s ease;
}
.search-bar__search-results .loader {
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 25px;
  color: #18bbb9;
}
.search-bar__search-results li a {
  display: block;
  margin-left: -30px;
  margin-right: -30px;
  padding: 3px 30px;
  font-weight: 300;
  font-size: 16px;
  color: #222222;
}
.search-bar__search-results li a mark {
  font-weight: bold;
  background: transparent;
}
.search-bar__search-results li a:hover {
  background-color: #e7f8f8;
  text-decoration: none;
}
.search-bar__search-results li.title p {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 12px;
  color: #666666;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 15px;
}
.search-bar__search-results li:first-of-type {
  margin-top: 0;
}
.search-bar__search-results li:last-of-type {
  margin-bottom: 0;
}
.search-bar__search-results.active {
  transform: translateY(0);
  opacity: 1;
  z-index: 999;
}

.sidebar > li {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 30px;
}
.sidebar > li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
.sidebar > li a.uk-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar > li a.uk-accordion-title span {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
.sidebar > li a.uk-accordion-title i,
.sidebar > li a.uk-accordion-title svg {
  font-size: 12px;
  color: #222222;
}
.sidebar > li.uk-open a.uk-accordion-title i,
.sidebar > li.uk-open a.uk-accordion-title svg {
  transform: rotate(180deg);
}
.sidebar__nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidebar__nav li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__nav li a .text {
  font-weight: 300;
  font-size: 16px;
  line-height: 28.8px;
  color: #222222;
}
.sidebar__nav li a .amount {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #666666;
}
.sidebar__nav li a:hover {
  text-decoration: none;
}
.sidebar__nav li a:hover .text {
  color: #18bbb9;
  font-weight: bold;
}
.sidebar__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__price > p {
  flex: 0 1 auto;
}
.sidebar__price > input[type=number] {
  width: 70px;
  flex: 0 0 70px;
  height: 50px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  /* Chrome, Safari, Edge, Opera */
  -moz-appearance: textfield;
}
.sidebar__price > input[type=number]::-webkit-outer-spin-button, .sidebar__price > input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sidebar__price > input[type=number]:focus {
  outline: none;
  border: 1px solid #18bbb9;
}
.sidebar__price > button[type=submit] {
  width: 50px;
  flex: 0 0 50px;
  height: 50px;
  cursor: pointer;
  background-color: #18bbb9;
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  font-size: 18px;
}
.sidebar__price > button[type=submit]:hover {
  background-color: #139795;
}

.size-picker .sizes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.size-picker .sizes button.size {
  height: 30px;
  border: 1px solid #e8e8e8;
  margin: 0px 5px 5px 0;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
  transition: 0.15s ease-in-out;
}
.size-picker .sizes button.size:hover, .size-picker .sizes button.size.uk-active {
  background-color: #e7f8f8;
  border: 1px solid #18bbb9;
  font-weight: bold;
}
.size-picker .sizes button.size.uk-active {
  font-weight: bold;
}

.uk-table-striped--alt tbody tr:nth-of-type(odd) {
  background-color: #e7f8f8;
}
.uk-table-wysiwyg thead th {
  background-color: #222222;
  color: #ffffff;
}
.uk-table-wysiwyg--alt thead th {
  background-color: #18bbb9;
}
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background-color: #18bbb9;
}
.uk-table-hover > tr:hover *,
.uk-table-hover tbody tr:hover * {
  color: #ffffff;
  font-weight: bold;
}

/*------------------------------------*\
  #COMPONENTS CMS
\*------------------------------------*/
.brand-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px 10px;
}
.brand-grid > a {
  border: 1px solid #e8e8e8;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  transition: border 0.25s ease, background-color 0.25s ease;
}
.brand-grid > a:hover {
  border: 1px solid #18bbb9;
  background-color: #e7f8f8;
}

@media (max-width: 960px) {
  .brand-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 640px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.uk-link {
  position: relative;
  padding-right: 20px;
}
.uk-link span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #18bbb9;
  transition: color 0.3s ease;
}
.uk-link i,
.uk-link svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s ease, color 0.3s ease;
  color: #18bbb9;
  font-size: 14px;
}
.uk-link:hover {
  text-decoration: none;
}
.uk-link:hover span,
.uk-link:hover i,
.uk-link:hover svg {
  color: #15a09e;
}
.uk-link:hover i,
.uk-link:hover svg {
  transform: translateX(6px) translateY(-50%);
}
.uk-link--black span,
.uk-link--black i,
.uk-link--black svg {
  color: #222222;
}

.uk-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 14px 21px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.uk-button-primary {
  background-color: #18bbb9;
  color: #ffffff;
}
.uk-button-primary:hover {
  background-color: #15a09e;
  color: #ffffff;
}

.uk-button-secondary {
  background-color: #e7f8f8;
  color: #18bbb9;
}
.uk-button-secondary:hover {
  background-color: #15a09e;
  color: #ffffff;
}

.button-icon {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  color: #ffffff;
  background-color: #18bbb9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-icon--large {
  width: 120px;
  height: 120px;
  font-size: 21px;
}
.button-icon:hover {
  background-color: #e7f8f8;
  color: #18bbb9;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.block-link {
  position: relative;
  background-color: #e7f8f8;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.block-link span {
  position: absolute;
  left: 25px;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #222222;
  transition: color 0.3s ease;
}
.block-link:hover {
  background-color: #18bbb9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-link:hover span {
  color: #ffffff;
}

.block-image-link {
  position: relative;
  background-color: #e7f8f8;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}
.block-image-link .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(34, 34, 34, 0.3);
  transition: background-color 0.3s ease;
}
.block-image-link span {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  transition: color 0.3s ease;
}
.block-image-link:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.block-image-link:hover .overlay {
  background-color: rgba(24, 187, 185, 0.7);
}

.cta * {
  color: #ffffff !important;
}
.cta .card__image {
  background-position: right bottom !important;
  background-size: contain !important;
}
.cta--img-bttm {
  padding-bottom: 200px;
}
.cta--img-bttm .card__image {
  position: absolute;
  height: 200px;
  right: 0 !important;
  width: 100% !important;
  bottom: 0;
}
.cta--img-bttm .pr-4 {
  padding-right: 0 !important;
}
.cta--large {
  background-color: #e7f8f8;
  padding: 60px;
}

.default-dropdown-nav {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.default-dropdown-nav .uk-dropdown-nav li {
  border-bottom: 1px solid #e8e8e8;
}
.default-dropdown-nav .uk-dropdown-nav li:last-of-type {
  border-bottom: 0;
}
.default-dropdown-nav .uk-dropdown-nav li a {
  padding: 10px 20px;
  font-size: 16px;
  color: #222222;
}
.default-dropdown-nav .uk-dropdown-nav li a:hover {
  background-color: #e7f8f8;
  color: #18bbb9;
}
.default-dropdown-nav .uk-dropdown-nav li.uk-active a {
  background-color: #18bbb9;
  color: #ffffff;
  font-weight: bold;
}

footer {
  background-color: #ffffff;
  padding: 75px 0px 35px;
}
footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
footer ul li a {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #222222;
}
footer .sub-footer {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .sub-footer a {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}

@media (max-width: 640px) {
  footer {
    background-color: #ffffff;
    padding: 25px 0;
  }
  footer .sub-footer {
    margin-top: 30px;
    flex-flow: column;
    align-items: flex-start;
  }
}
.input-errors p {
  color: #ff2424;
  margin-bottom: 0px;
  margin-top: 0px;
}

.custom-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  color: #18bbb9;
}
.custom-checkbox input[type=checkbox] {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: none;
  flex: 0 0 20px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.custom-checkbox input[type=checkbox]:checked {
  border: 1px solid #18bbb9;
  background-color: #18bbb9;
  background-image: url("/dist/assets/images/icons/check.svg");
  background-size: 12px 10px;
}
.custom-checkbox input[type=checkbox][disabled] {
  background-color: #b7b2b2;
  border: 1px solid #b7b2b2;
}
.custom-checkbox div {
  flex: 1 0 auto;
}
.custom-checkbox a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #18bbb9;
  text-decoration: underline;
  margin: 0 0 0 4px;
  display: contents;
}
.custom-checkbox a:hover, .custom-checkbox a:active, .custom-checkbox a:focus {
  color: #139795;
}
.custom-checkbox.uk-form-danger {
  color: #ff2424;
}
.custom-checkbox.uk-form-danger input[type=checkbox] {
  border: 2px solid #ff2424;
}
.custom-checkbox.disabled p {
  color: #b7b2b2;
}
.custom-checkbox.disabled:hover {
  cursor: no-drop;
}
.custom-checkbox.disabled input[type=checkbox]:hover {
  cursor: no-drop;
}
.custom-checkbox--big input[type=checkbox] {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
}
.custom-checkbox--big input[type=checkbox]:checked {
  background-image: url("/dist/assets/images/icons/check.svg");
  background-size: 24px 20px;
}

.custom-radio {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
.custom-radio input[type=radio] {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-right: 10px;
  margin-top: 2px;
  background-color: transparent;
  border: 1px solid #e8e8e8;
}
.custom-radio input[type=radio]:checked {
  border: 1px solid #18bbb9;
  background-color: #18bbb9;
  background-size: 150%;
}

.uk-input,
.uk-textarea {
  height: 50px;
  background-color: transparent;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 0px 20px;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.uk-input:focus,
.uk-textarea:focus {
  border: 1px solid #18bbb9;
  outline: none;
}
.uk-input.uk-form-danger,
.uk-textarea.uk-form-danger {
  border: 2px solid #ff2424;
}
.uk-input.uk-form-danger::placeholder,
.uk-textarea.uk-form-danger::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff2424;
  opacity: 1;
  /* Firefox */
}
.uk-input.uk-form-danger:-ms-input-placeholder,
.uk-textarea.uk-form-danger:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ff2424;
}
.uk-input.uk-form-danger::-ms-input-placeholder,
.uk-textarea.uk-form-danger::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ff2424;
}

.uk-textarea {
  padding: 20px;
  height: auto;
}

.uk-form-label {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 10px;
  display: block;
}

form .form-url {
  display: none;
}

select.custom-select {
  padding: 0px 20px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% #fff;
  background-position: right 20px center;
}
select.custom-select:active, select.custom-select:focus {
  text-decoration: none;
  outline: none;
  border: 1px solid #18bbb9;
}

.home-banners {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas: "left top-right" "left bottom-right";
  height: 460px;
}
.home-banners--one {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0;
}
.home-banners--two {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 30px;
}
.home-banners--left {
  grid-area: left;
}
.home-banners--top-right {
  grid-area: top-right;
}
.home-banners--bottom-right {
  grid-area: bottom-right;
}
.home-banners--top-right .home-banner__content, .home-banners--bottom-right .home-banner__content {
  padding: 40px 33% 40px 40px;
}

.home-banner {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  position: relative;
  background-color: #18bbb9;
}
.home-banner__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 1;
}
.home-banner__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 60px 25% 60px 60px;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.home-banner--image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e7f8f8;
}
.home-banner--image .home-banner__content * {
  color: #ffffff !important;
}

@media (max-width: 960px) {
  .home-banner__content {
    padding: 32px;
  }

  .home-banners {
    gap: 15px 15px;
    height: 400px;
    grid-template-columns: 1.5fr 1fr;
  }
  .home-banners--one {
    gap: 0;
  }
  .home-banners--two {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 15px;
  }
  .home-banners--top-right .home-banner__content, .home-banners--bottom-right .home-banner__content {
    padding: 32px;
  }
}
@media (max-width: 640px) {
  .home-banner {
    height: 210px;
  }
  .home-banner__content {
    padding: 20px !important;
  }

  .home-banners {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "left" "top-right" "bottom-right";
    height: 110vh;
  }
}
@media all and (-ms-high-contrast: none) {
  .home-banners {
    display: -ms-grid;
    -ms-grid-columns: 2fr 1fr;
    -ms-grid-rows: 1fr 1fr;
  }
  .home-banners--left {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .home-banners--top-right {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .home-banners--bottom-right {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
}
.loader {
  display: inline-block;
}

.main-navbar {
  display: grid;
  grid-template-columns: 1fr 2fr 1.5fr;
  gap: 20px 20px;
  padding: 25px 0;
  align-content: center;
}
.main-navbar__left .uk-logo {
  display: block;
}
.main-navbar__left img {
  max-width: 100%;
}
.main-navbar__center {
  display: flex;
  align-items: center;
}
.main-navbar__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-navbar__right > a,
.main-navbar__right > .dropdown-item > a {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #222222;
  margin-right: 30px;
}
.main-navbar__right > a span,
.main-navbar__right > .dropdown-item > a span {
  margin-top: 10px;
  font-weight: normal;
  font-size: 16px;
}
.main-navbar__right > a:hover,
.main-navbar__right > .dropdown-item > a:hover {
  color: #18bbb9;
  text-decoration: none;
}
.main-navbar__right > a:last-child,
.main-navbar__right > .dropdown-item > a:last-child {
  margin-right: 0;
}
.main-navbar__right > a.cart-button,
.main-navbar__right > .dropdown-item > a.cart-button {
  position: relative;
}
.main-navbar__right > a.cart-button .amount,
.main-navbar__right > .dropdown-item > a.cart-button .amount {
  position: absolute;
  top: -7px;
  left: calc(50% + 3px);
  width: 18px;
  height: 18px;
  background-color: #18bbb9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin: 0;
  z-index: 9;
  border-radius: 9999px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.mobile-menu .uk-offcanvas-bar {
  width: 375px;
  left: -375px;
  padding: 0;
  position: absolute;
  overflow-y: scroll;
  transition: left 0.6s ease;
}
.mobile-menu.uk-open .uk-offcanvas-bar {
  left: 0;
}
.mobile-menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #18bbb9;
  padding: 11px 35px;
}
.mobile-menu__header .uk-svg {
  color: #ffffff;
}
.mobile-menu__header .uk-offcanvas-close {
  position: relative;
  top: auto;
  right: auto;
  background-color: transparent;
  color: #ffffff;
  border: 0;
  font-size: 21px;
}
.mobile-menu__content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.mobile-menu__content > li:nth-child(odd) a, .mobile-menu__content > li:nth-child(odd) section {
  background-color: #e7f8f8;
}
.mobile-menu__content > li:nth-child(odd) a.mobile-category-nav-item .arrow__previous, .mobile-menu__content > li:nth-child(odd) section.mobile-category-nav-item .arrow__previous {
  border-color: #ffffff;
}
.mobile-menu__content > li:nth-child(odd) a.mobile-category-nav-item .arrow__next, .mobile-menu__content > li:nth-child(odd) section.mobile-category-nav-item .arrow__next {
  border-color: #ffffff;
}
.mobile-menu__content > li:nth-child(even) section.mobile-category-nav-item .arrow__previous {
  border-color: #e7f8f8;
}
.mobile-menu__content > li:nth-child(even) section.mobile-category-nav-item .arrow__next {
  border-color: #e7f8f8;
}
.mobile-menu__content > li .mobile-category-nav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.mobile-menu__content > li .mobile-category-nav-item .arrow {
  padding: 10px 35px;
}
.mobile-menu__content > li .mobile-category-nav-item .arrow__previous {
  border-right: 1px solid #e7f8f8;
}
.mobile-menu__content > li .mobile-category-nav-item .arrow__next {
  border-left: 1px solid #e7f8f8;
}
.mobile-menu__content > li .mobile-category-nav-item .arrow i,
.mobile-menu__content > li .mobile-category-nav-item .arrow svg {
  color: #909090;
}
.mobile-menu__content > li .mobile-category-nav-item a {
  padding: 10px 35px;
  width: 100%;
}
.mobile-menu__content > li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.mobile-menu__content > li a section:last-of-type i,
.mobile-menu__content > li a section:last-of-type svg {
  color: #909090;
}
.mobile-menu__content > li a .uk-svg {
  color: #222222 !important;
}
.mobile-menu__accordion {
  margin: 0;
}
.mobile-menu__accordion li {
  margin: 0 !important;
}
.mobile-menu__accordion li a.uk-accordion-title {
  padding: 14px 35px;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-menu__accordion li .uk-accordion-content {
  margin: 0;
  padding: 20px 35px;
}
.mobile-menu__accordion li:nth-child(odd) a.uk-accordion-title {
  background-color: #f8f8f8;
}
.mobile-menu__accordion li.uk-open a i,
.mobile-menu__accordion li.uk-open a svg {
  transform: rotate(180deg);
}
.mobile-menu__apply {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e7f8f8;
  padding: 10px;
}

.mobile-nav {
  background-color: #e7f8f8;
  padding: 20px 0;
}
.mobile-nav .uk-navbar-toggle {
  color: #222222;
  margin-right: 20px;
  padding: 0;
  min-height: 0;
}
.mobile-nav .uk-logo {
  padding: 0;
}
.mobile-nav .uk-navbar-item {
  min-height: 0;
}
.mobile-nav .uk-navbar-nav li a {
  padding: 0 10px;
  min-height: 0;
  color: #222222;
}

@media (max-width: 640px) {
  #mobile-nav .uk-offcanvas-bar {
    width: 90vw;
    left: -90vw;
  }
  #mobile-nav.uk-open .uk-offcanvas-bar {
    left: 0;
  }
}
@media (max-width: 960px) {
  .mobile-slider-wrapper {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .mobile-slider-wrapper .mobile-slider {
    overflow: visible;
  }
}
.newsletter form {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 0px;
}
.newsletter form button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}
.newsletter .input-errors {
  color: #ff2424;
}
.newsletter .input-errors p {
  color: #ff2424;
}

.product-navbar {
  background-color: #18bbb9;
  padding: 15px;
}
.product-navbar .uk-navbar-nav > li > a {
  margin: 0;
  padding: 0px 15px;
  min-height: 0;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.product-navbar .uk-navbar-nav > li > a:hover {
  font-weight: bold;
}
.product-navbar .uk-navbar-dropdown {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 3px 3px;
  padding: 22px 30px 30px;
}
.product-navbar .uk-navbar-dropdown .uk-nav-divider {
  margin: 25px 0;
}
.product-navbar .uk-navbar-dropdown .uk-nav-sub-header {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 12px;
  color: #222222;
}
.product-navbar .uk-navbar-dropdown .uk-nav li a {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #222222;
  padding: 0;
}
.product-navbar .uk-navbar-dropdown .uk-nav li a:hover {
  color: #18bbb9;
  text-decoration: underline;
}
.product-navbar .uk-navbar-dropdown .uk-nav-header a {
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #222222 !important;
  text-transform: none !important;
}
.product-navbar .uk-navbar-dropdown .uk-nav-header a:hover {
  color: #18bbb9 !important;
  text-decoration: none !important;
}
.product-navbar .uk-navbar-dropdown .uk-nav-header:not(:first-child) {
  margin-top: 10px;
}

.slider-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #18bbb9;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.slider-button:focus, .slider-button:active {
  background-color: #ffffff;
  color: #18bbb9;
  text-decoration: none;
}

.top-banner {
  background-color: #18bbb9;
}
.top-banner--title {
  color: #ffffff;
}
.top-banner--text {
  color: #ffffff;
}
.top-banner--text p {
  color: #ffffff;
}
.top-banner--text p:last-of-type {
  margin-bottom: 0;
}

.top-bar {
  background-color: #e7f8f8;
  padding: 15px 0;
}
.top-bar__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.top-bar__nav > li {
  margin: 0px 10px;
}
.top-bar__nav > li:first-of-type {
  margin-left: 0;
}
.top-bar__nav > li:last-of-type {
  margin-right: 0;
}
.top-bar__nav > li > a {
  color: #222222;
  font-size: 14px;
  line-height: 21px;
}
.top-bar__nav > li > a:hover {
  text-decoration: none;
  color: #18bbb9;
}