/* General
-------------------------------------------------- */

* {
  box-sizing             : border-box;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x:hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url('/dist/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

// ==========[ LAZYSIZES ]==========

// lazysizes
.lazyload, .lazyloading{
  opacity: 0;
}

.lazyloaded{
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper{
  position: relative;

  img{
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

// TER INFO: bijbehorende HTML:
// Voor img tag:
// <div class="lazyload-wrapper" style="padding-bottom: <?= getImageRatio($page->image) ?>%;">
//   <img data-src="<?= $page->image->size(1600, 1600, ['cropping' => false])->url ?>" alt="<?= $page->image->description ?>" class="lazyload">
// </div>
// Voor backgroundimage:
// <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div>


/* Product detail page
-------------------------------------------------- */

.cart-container {
  display        : flex;
  justify-content: flex-start;
  align-items    : center;
}